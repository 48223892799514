<template>
	<v-container class="h-100 layout--pdf">
		<sp-app-bar :spNav="false"/>

		<transition
			name="fade"
			mode="out-in"
			:duration="500"
		>
			<v-main :style="mainStyles" class="">
				<router-view/>
			</v-main>
		</transition>
	</v-container>
</template>


<script>
import SpAppBar from "@/components/template/SpAppBar";

export default {
	name: 'LayoutDefault',
	components: {SpAppBar},
	computed: {
		mainStyles() {
			return this.$vuetify.breakpoint.lgAndUp ? 'margin-left: 0' : '';
		},
	},
	data: () => ({}),
};
</script>
